import { Controller } from "@hotwired/stimulus";
// confirm("Are you sure?");
export default class extends Controller {
  confirm(event) {
    let confirmation = confirm("Are you sure?");

    if (!confirmation) {
      event.preventDefault();
    }
  }
}
