import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "street",
    "city",
    "state",
    "country",
    "recordingLongitude",
    "recordingLatitude",
  ];

  static values = {
    apiKey: String,
  };

  connect() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        this.success.bind(this),
        this.error.bind(this),
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      // console.warn("Geolocation is not available in your browser.");
    }
  }

  success(position) {
    // console.log("success");
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    this.setCoordinates(latitude, longitude);
    this.reverseGeocode(latitude, longitude);
  }

  error(error) {
    // console.error("Error obtaining location:", error.message);
    // You might want to show an error message to the user here
  }

  setCoordinates(latitude, longitude) {
    this.recordingLatitudeTarget.value = latitude;
    this.recordingLongitudeTarget.value = longitude;
  }

  reverseGeocode(latitude, longitude) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${this.apiKeyValue}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const result = data.results[0].components;
          this.streetTarget.value = result.road || "";
          this.cityTarget.value = result.city || result.town || "";
          this.stateTarget.value = result.state || "";
          this.countryTarget.value = result.country || "";
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        // You might want to show an error message to the user here
      });
  }
}
